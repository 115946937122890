<div class="container">
  <div class="row">
    <div class="col-lg-12 text-center">
      <ng-container *ngIf="last_update">
        <p>
          Ultima actualizacion:
          {{ last_update | date : "dd MMM yyyy hh:mm:ss a" }}
        </p>
      </ng-container>
      <div class="table-responsive">
        <table
          class="table table-striped table-bordered table-hover"
          style="background-color: #fff"
        >
          <tr style="background-color: #005ea0; color: #fff">
            <th style="color: #fff !important">
              <h4><b>#</b></h4>
            </th>
            <th>MOVIL</th>
            <th>FECHA</th>
          </tr>
          <ng-container *ngIf="!datas">
            <b style="color: #8c8c8c">No hay datos.</b>
          </ng-container>
          <ng-container *ngIf="datas">
            <ng-container *ngFor="let data of datas; let i = index">
              <tr>
                <td style="background-color: #005ea0; color: #fff">
                  <b style="color: #ccc">{{ i + 1 }}.</b>
                </td>
                <td>
                  <span style="color: #8c8c8c">{{ data.unit_name }}</span>
                </td>
                <td>
                  <b style="color: #005ea0"
                    ><span style="color: #8c8c8c">{{ data.pos_time }}</span></b
                  >
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>
